import { useEffect, useState } from "react";
import { signIn, useSession } from "next-auth/react";
import { useQuery } from "@apollo/client";
import { GET_PARAMETERS } from "@/data/queries/user.gql";
import { logOffInactivityMinutesVar } from "@/utils/variables";
import Router from "next/router";
import { User } from "@/commons/interfaces/User";

export function getUserLocal() {
  const users = localStorage.getItem("users");
  return users ? JSON.parse(users) : [];
}

function saveUserLocal(user: User) {
  const users = localStorage.getItem("users");
  const newUserLocal = {
    first_name: user.first_name,
    last_name: user.last_name,
    dni: user.dni,
    id: user.id,
  };
  const usersArray = users ? JSON.parse(users) : [];
  const userSaved = usersArray.find((u) => u.id === user?.id);
  if (!userSaved) {
    usersArray.push(newUserLocal);
    localStorage.setItem("users", JSON.stringify(usersArray));
  }
}

export function useLogin(
  t: (text: string, params?: any) => string,
  ip: string,
) {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [modal, setModal] = useState<Record<string, string | boolean>>();
  const [waitSignIn, setWaitSignIn] = useState(false);
  const { data: parameters } = useQuery(GET_PARAMETERS);
  const { data: session } = useSession();

  useEffect(() => {
    if (waitSignIn && session) {
      logOffInactivityMinutesVar(
        parameters.getParameters.logoff_inactivity_minutes,
      );
      redirectUser();
    }
  }, [waitSignIn, session]);

  const redirectUser = () => {
    const user = session?.user as User;
    if (user?.change_password_in_acccess && !user?.last_access) {
      setModal({
        title: t("user.first_income.title"),
        description: t("user.first_income.description"),
        resetPassword: true,
      });
      return;
    } else if (user?.change_password_in_acccess && user?.last_access) {
      setModal({
        title: t("user.expired_password.title"),
        description: t("user.expired_password.description"),
        resetPassword: true,
      });
      return;
    } else {
      saveUserLocal(user);
      localStorage.setItem("isLogin", "true");
      Router.push("/app");
    }
  };

  const handleErrors = (err) => {
    const minutes = parameters?.getParameters?.login_min_attemps_minutes;
    const attemps = err?.extensions?.info?.attemps;
    if (err?.code === "AddressNotAllowedError") {
      setModal({
        title: t(`user.error.not_allowed.title`),
        description: t(`user.error.not_allowed.description`, { ip }),
      });
    } else if (err?.message === "user_not_exists") {
      setModal({
        title: t(`user.error.not_exist.title`),
        description: t(`user.error.not_exist.description`),
      });
    } else if (err?.message === "user_not_active") {
      setModal({
        title: t(`user.error.not_active.title`),
        description: t(`user.error.not_active.description`),
      });
    } else if (!attemps || attemps == 3 || attemps > 5) {
      const state = err?.extensions?.info?.lock_state?.toLowerCase();
      setModal({
        title: t(`user.error.${state}.title`),
        description: t(`user.error.${state}.description`, {
          minutes,
          textMinutes: t("minutes"),
        }),
      });
    } else {
      setError(
        t(`user.error.incorrect_${attemps}`, {
          minutes,
          textMinutes: t("minutes"),
        }),
      );
    }
  };

  async function signInHandler() {
    setIsSubmitting(true);
    try {
      const res = await signIn("credentials", {
        dni: user,
        password: password,
        redirect: false,
        callbackUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/auth/reset`,
      });

      if (res.ok) {
        setWaitSignIn(true);
      } else {
        let error = JSON.parse(res.error);
        handleErrors(error);
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
  }

  return {
    signIn: signInHandler,
    disabled: !user || !password || isSubmitting,
    user,
    setUser,
    setPassword,
    modal,
    setModal,
    error,
    setError,
    isSubmitting,
  };
}

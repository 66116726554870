import Button from "@/components/button";
import Input from "@/components/input";
import Select from "@/containers/auth/signin/select";
import LayoutAuth from "../layout-auth";
import useTranslation from "next-translate/useTranslation";
import ModalDialog from "@/components/modal/dialog";
import Router from "next/router";
import { getUserLocal, useLogin } from "./hooks/useLogin";
import { useEffect, useState } from "react";

// Se revisa si hay lista de usuarios locales
// Completa usuario y contraseña
// Se ejecuta signIn de next-auth
// Si falla:
//  - La contraseña incorrecta se muestra texto
//  - El usuario no existe se muestra un modal
//  - Si se superan los intentos fallidos muestra modal (parametros guardados en base)
// Si ok:
//  - Se espera que haya sesion de next-auth
//  - Verifica si hay que resetear contraseña por primer acceso y muestra modal para redireccionar a reset
//  - Verifica si hay que resetear contraseña y muestra modal para redireccionar a reset
// Se guarda el usuario localmente para la lista de login
// Redirect

export function SignInForm({ ip }) {
  const { t } = useTranslation("signin");
  const [localUsers, setLocalUsers] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    signIn,
    setUser,
    setPassword,
    disabled,
    modal,
    setModal,
    error,
    setError,
    isSubmitting,
  } = useLogin(t, ip);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await signIn();
  };

  useEffect(() => {
    setLocalUsers(getUserLocal());
  }, []);

  return (
    <LayoutAuth animation={true}>
      <ModalDialog
        showModal={!!modal}
        setShowModal={(show) => !show && setModal(undefined)}
        title={modal?.title.toString()}
        content={modal?.description.toString()}
        submitLabel={t("common.accept")}
        onClickSubmit={() => {
          if (modal.resetPassword) Router.push("/auth/reset");
        }}
      />
      <div>
        <h1 className="text-white text-[32px] font-bold leading-[42px] text-center mb-[12px]">
          {t("login.title")}
        </h1>
        <p className="text-white text-[20px] font-normal leading-[26px] text-center mb-[32px]">
          {t("login.subtitle")}
        </p>
        <div className="max-w-[600px] 2xl:w-[600px]">
          <form onSubmit={handleSubmit}>
            <Select
              setUser={setUser}
              setError={setError}
              placeholder={t("user.placeholder")}
              label={t("user.label")}
              name={"selectUser"}
              allUsers={localUsers}
            />
            <div className={`${error ? "mb-16" : "mb-[32px]"} mt-[16px]`}>
              <Input
                label={t("password.label")}
                placeholder={t("password.placeholder")}
                onChange={(value) => setPassword(value.toString())}
                togglePasswordVisibility={togglePasswordVisibility}
                icon={showPassword ? "eye" : "eye-slash"}
                type={showPassword ? "text" : "password"}
                sizeIcon={32}
              />
            </div>
            <Button
              disabled={disabled}
              type="submit"
              label={isSubmitting ? t("authenticating") : t("submit")}
              variant={"primary"}
              size={"large"}
              iconPosition={"left"}
              icon={"login-1"}
            />

            {error && <p>{error}</p>}
          </form>
        </div>
      </div>
    </LayoutAuth>
  );
}
